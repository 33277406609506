import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div>
      <p>Hello</p>
    </div>
  );
}

export default App;
